import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trial-date-selector"
export default class extends Controller {
  static targets = [ "trialPeriodSelector", "checkbox", "dateSelect" ];

  connect() {
    this.checkboxTarget.checked = false;
  }

  toggle() {
    this.updateTrialPeriodSelector();
  }

  updateTrialPeriodSelector() {
    if (this.checkboxTarget.checked) {
      this.trialPeriodSelectorTarget.classList.remove("hidden");
    } else {
      this.trialPeriodSelectorTarget.classList.add("hidden");
    }

    for (const selector of this.dateSelectTargets) {
      selector.disabled = !this.checkboxTarget.checked;
    }
  }
}
