import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-all"
export default class extends Controller {
  static targets = ["selectAllCheckbox", "checkbox"]

  toggleAll() {
    const checked = this.selectAllCheckboxTarget.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
    })
  }

  updateSelectAll() {
    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked)
    this.selectAllCheckboxTarget.checked = allChecked
  }
}