import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spot-instance-selector"
export default class extends Controller {
  static targets = [ "spotInstanceSelector", "profileSelectorContainer" ]
  static values = { fetchStreamUrl: String }

  connect() {
    if (!(this.hasSpotInstanceSelectorTarget && this.spotInstanceSelectorTarget.checked)) return;

    this.fetchAvailableProfiles();
  }

  async fetchAvailableProfiles() {
    this.profileSelectorContainerTarget.innerHTML = `
      <div class="animate-pulse space-x-2 flex flex-wrap gap-2">
        <div class="w-44 h-52 p-2 rounded-lg bg-gray-400"></div>
        <div class="w-44 h-52 p-2 rounded-lg bg-gray-400"></div>
        <div class="w-44 h-52 p-2 rounded-lg bg-gray-400"></div>
      </div>
    `;

    const useSpotInstance = this.spotInstanceSelectorTarget.checked;

    const response = await fetch(`${this.fetchStreamUrlValue}?use_spot_instance=${useSpotInstance}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    });

    if (!response.ok) return console.error('Failed to fetch turbo stream: ', response.statusText);

    const turboStream = await response.text();

    await new Promise(resolve => {
      requestAnimationFrame(() => {
        Turbo.renderStreamMessage(turboStream);
        resolve();
      });
    });

    await new Promise(resolve => requestAnimationFrame(resolve));

    this._refreshProfileTabs();
  }

  _refreshProfileTabs() {
    const profileTabsElement = document.querySelector("[data-controller='profile-tabs']");
    if (!profileTabsElement) return;

    const profileTabsController = this.application.getControllerForElementAndIdentifier(
      profileTabsElement,
      "profile-tabs"
    );

    if (profileTabsController) {
      profileTabsController.refreshCurrentTab();
    }
  }
}
