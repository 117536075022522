import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["time"];
  static values = { startedAt: Number };

  connect() {
    this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(this.updateTime.bind(this), 1000);
  }

  updateTime() {
    let elapsedTimeInSeconds = Math.floor(
      new Date().getTime() / 1000 - this.startedAtValue
    );
    let hours = Math.floor(elapsedTimeInSeconds / 3600);
    let minutes = Math.floor((elapsedTimeInSeconds % 3600) / 60);
    let seconds = elapsedTimeInSeconds % 60;

    this.timeTarget.textContent = `${this.pad(hours)}:${this.pad(
      minutes
    )}:${this.pad(seconds)}`;
  }

  pad(number) {
    return String(number).padStart(2, "0");
  }

  disconnect() {
    clearInterval(this.timer);
  }
}
