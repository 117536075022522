import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="display-uploaded-file-name"
export default class extends Controller {
  static targets = [ "fileField", "fileName" ];

  showUploadedFileName(event) {
    const file = event.target.files[0];

    this.fileNameTarget.textContent = file.name;
  }
}
