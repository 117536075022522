import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]
  static values = { selectedIndex: Number }

  connect() {
    const index = this.hasSelectedIndexValue ? this.selectedIndexValue : 0
    this.currentTabIndex = index
    this.showTab(index)
  }

  change(event) {
    event.preventDefault()
    const index = parseInt(event.currentTarget.dataset.profileTabsIndex)
    this.currentTabIndex = index 
    this.showTab(index)
  }

  showTab(index) {
    this.currentTabIndex = index 

    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        tab.classList.add('text-blue-500', 'border-blue-500')
        tab.classList.remove('text-gray-500')
      } else {
        tab.classList.remove('text-blue-500', 'border-blue-500')
        tab.classList.add('text-gray-500')
      }
    })

    this.contentTargets.forEach((content, i) => {
      if (i === index) {
        content.classList.remove('hidden')
        content.querySelectorAll('input, select, textarea, button').forEach(input => {
          input.disabled = false
        })
      } else {
        content.classList.add('hidden')
        content.querySelectorAll('input, select, textarea, button').forEach(input => {
          input.disabled = true
        })
      }
    })
  }
  
  refreshCurrentTab() {
    this.showTab(this.currentTabIndex)
  }
}
