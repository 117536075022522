import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="data-catalog-slim-select"
export default class extends Controller {
  static values = { filter: String, searchParams: Object, fetchOptionsUrl: String, selectedOptions: Array, age: Object }
  static outlets = [ "distribution-chart", "filter-selector" ]

  connect() {
    this.slimSelect = new SlimSelect({
      select: this.element,
      events: {
        afterChange: () => {
          const selectedChart = this.distributionChartOutletElement.getAttribute('value');
          const hiddenInputField = this.filterSelectorOutlet.hiddenInputFieldChartTarget;

          hiddenInputField.value = selectedChart;

          this.element.form.requestSubmit();
        },
        beforeOpen: async () => {
          await this.fetchFilterOptions();
        }
      },
      settings: {
        placeholderText: this.data.get("placeholder"),
        searchText: 'Searching...',
      }
    });

    // when the url already contains the filter and its options values, we want to set the selected values of the
    // slim select to contain the values in the page params. But in order to do that, we need to fetch the options.
    this.checkSelectedOptionsAndFetchFilterOptions();
  }

  async checkSelectedOptionsAndFetchFilterOptions() {
    if (this.selectedOptionsValue.length > 0) {
      await this.fetchFilterOptions();
    }
  }

  async fetchFilterOptions() {
    // The slimselect library does not have a direct API method to get the length of the data. Therefore,
    // we need to get all the data and check the length.
    if (this.slimSelect.getData().length > 0) return;

    const searchQuery = this.objectToPageParamsString(this.searchParamsValue);

    const minAge = this.ageValue.min_age ? `&min_age=${this.ageValue.min_age}` : '&min_age=';
    const maxAge = this.ageValue.max_age ? `&max_age=${this.ageValue.max_age}` : '&max_age=';

    try {
      const response = await fetch(
        `${this.fetchOptionsUrlValue}?filter=${this.filterValue}&${searchQuery}&min_age=${minAge}&max_age=${maxAge}`);

      if (!response.ok) return console.error('Failed to fetch options:', response.statusText);

      const data = await response.text();

      // The data is expected to be in the format of { filter: [[ text, value ], ...] }
      // We need to parse the data and map it to the format that slim-select expects which is [{ text, value }, ...]
      const options = JSON.parse(data)[this.filterValue].map(([text, value]) => ({ text, value }));

      if (options.length > 0) {
        this.slimSelect.setData(options);

        if (this.selectedOptionsValue.length > 0) {
          this.slimSelect.setSelected(this.selectedOptionsValue, false);
        }
      } else {
        this.slimSelect.config.settings.searchText = 'No results found';
      }
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  }

  // given an object, return a query string i.e. { a: [1, 2, 3], b: [2] } => 'a[]=1&a[]=2&a[]=3&b[]=2'
  objectToPageParamsString(obj) {
    return Object.entries(obj)
      .map(([key, values]) => {
        if (Array.isArray(values)) {
          return values.map(value => `${key}[]=${value}`).join('&');
        } else {
          return `${key}=${values}`;
        }
      })
      .join('&');
  }
}
