import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["dropdownMenuButton", "dropdownMenu"];

  connect() {
    this.outerClickDropdownHandler = (event) => this.outerClickDropdown(event);
  }

  toggleDropdown() {
    const isOpen = !this.dropdownMenuTarget.classList.contains("hidden");

    if (isOpen) {
      this.closeDropdownMenu();
    } else {
      this.openDropdownMenu();
    }
  }

  openDropdownMenu() {
    this.dropdownMenuTarget.classList.remove("hidden");
    setTimeout(() => {
      this.dropdownMenuTarget.classList.add("opacity-100");
    }, 50);

    document.addEventListener("click", this.outerClickDropdownHandler);
  }

  closeDropdownMenu() {
    this.dropdownMenuTarget.classList.remove("opacity-100");
    this.dropdownMenuTarget.classList.add("opacity-0");

    const transitionEndHandler = () => {
      this.dropdownMenuTarget.classList.add("hidden");
      this.dropdownMenuTarget.removeEventListener("transitionend", transitionEndHandler);
    };

    this.dropdownMenuTarget.addEventListener("transitionend", transitionEndHandler);
    document.removeEventListener("click", this.outerClickDropdownHandler);
  }

  outerClickDropdown(event) {
    if (!this.dropdownMenuButtonTarget.contains(event.target) && !this.dropdownMenuTarget.contains(event.target)) {
      this.closeDropdownMenu();
    }
  }
}
