import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template"
export default class extends Controller {
  static targets = [ "dropdownMenuButton", "dropdownMenu", "sidebarMenu", "menuButton", "overlay" ];
  static classes = [ "invisibleSidebar" ];

  connect() {
    this.sidebarMenuTarget.classList.add(...this.invisibleSidebarClasses);

    this.outerClickDropdownHandler = (event) => this.outerClickDropdown(event);
    this.outerClickSidebarHandler = (event) => this.outerClickSidebar(event);
  }

  toggleDropdown() {
    const isOpen = !this.dropdownMenuTarget.classList.contains("hidden");

    if (isOpen) {
      this.closeDropdownMenu();
    } else {
      this.openDropdownMenu();
    }
  }

  openDropdownMenu() {
    this.dropdownMenuTarget.classList.remove("hidden");
    setTimeout(() => {
      this.dropdownMenuTarget.classList.add("opacity-100");
    }, 50);

    document.addEventListener("click", this.outerClickDropdownHandler);
  }

  closeDropdownMenu() {
    this.dropdownMenuTarget.classList.remove("opacity-100");
    this.dropdownMenuTarget.classList.add("opacity-0");

    const transitionEndHandler = () => {
      this.dropdownMenuTarget.classList.add("hidden");
      this.dropdownMenuTarget.removeEventListener("transitionend", transitionEndHandler);
    };

    this.dropdownMenuTarget.addEventListener("transitionend", transitionEndHandler);
    document.removeEventListener("click", this.outerClickDropdownHandler);
  }

  showNavigationMenu() {
    this.sidebarMenuTarget.classList.remove(...this.invisibleSidebarClasses);
    this.overlayTarget.classList.remove('opacity-0');
    this.overlayTarget.classList.add('opacity-50');

    document.addEventListener('click', this.outerClickSidebarHandler);
  }

  closeSidebarMenu() {
    this.sidebarMenuTarget.classList.add(...this.invisibleSidebarClasses);
    this.overlayTarget.classList.remove('opacity-50');
    this.overlayTarget.classList.add('opacity-0');

    document.removeEventListener('click', this.outerClickSidebarHandler);
  }

  outerClickDropdown(event) {
    if (!this.dropdownMenuButtonTarget.contains(event.target) && !this.dropdownMenuTarget.contains(event.target)) {
      this.closeDropdownMenu();
    }
  }

  outerClickSidebar(event) {
    const isSidebarVisible = (this.sidebarMenuTarget.classList.contains("md:invisible") || this.sidebarMenuTarget.classList.contains("invisible"));

    if (!isSidebarVisible && !this.menuButtonTarget.contains(event.target) && !this.sidebarMenuTarget.contains(event.target)) {
      this.closeSidebarMenu();
    }
  }
}
