import { Controller } from "@hotwired/stimulus"
import { Chart } from 'chart.js';

// Connects to data-controller="cost-summary-chart"
export default class extends Controller {
  static targets = [ "canvas" ]
  static values = { chartData: Object }

  connect() {
    const labels = Object.keys(this.chartDataValue);
    const data = Object.values(this.chartDataValue);
    const ctx = this.canvasTarget.getContext('2d');

    this.createChart(labels, data, ctx);
  }

  createChart(axisLabels, dataValues, ctx) {
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: axisLabels,
            datasets: [{
                data: dataValues,
                backgroundColor: 'rgba(122, 136, 206, 0.5)',
                borderColor: 'rgba(122, 136, 206, 1)',
                borderWidth: 2
            }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: true,
                text: 'Total Cost (€)',
                font: {
                  size: 16,
                }
              },
              ticks: {
                maxTicksLimit: 5,
                precision: 0,
                stepSize: 50,
                min: 0
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              displayColors: false
            }
          }
        }
    });
  }
}
