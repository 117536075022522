import { Controller } from "@hotwired/stimulus"
import { Chart } from 'chart.js';

// Connects to data-controller="distribution-chart"
export default class extends Controller {
  static targets = [ "canvasChart" ]
  static values = { xAxisLabel: String, chartData: Object }

  connect() {
    const labels = Object.keys(this.chartDataValue);
    labels.forEach((label, index) => {
      if (label === '') {
        labels[index] = 'null';
      }
    });

    const data = Object.values(this.chartDataValue);
    const ctx = this.canvasChartTarget.getContext('2d');

    this.createChart(labels, data, ctx, this.xAxisLabelValue);
  }

  createChart(axisLabels, dataValues, ctx, distChartType) {
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: axisLabels,
            datasets: [{
                label: 'Cases',
                data: dataValues,
                backgroundColor: 'rgba(122, 136, 206, 0.5)',
                borderColor: 'rgba(122, 136, 206, 1)',
                borderWidth: 2
            }]
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: true,
                text: 'Number of cases',
                font: {
                  size: 16,
                }
              },
              ticks: {
                maxTicksLimit: 5,
                precision: 0,
                stepSize: 50,
                min: 0
              }
            },
            x: {
              title: {
                display: true,
                text: distChartType,
                font: {
                  size: 16
                }
              },
              ticks: {
                callback: function(value) {
                  const label = axisLabels[value];

                  return label.length > 10 ? label.substring(0, 10) + '...' : label;
                }
              },
              grid: {
                display: false
              }
            },
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function(context) {
                  let title = context[0].label || 'N/A';

                  return distChartType === 'Age' ? title + ' years' : title;
                },
                label: function(context) {
                  const value = context.parsed.y;

                  return value > 1 ? value + ' cases' : value + ' case';
                }
              },
              displayColors: false,
              mode: 'nearest',
              intersect: false,
            }
          }
        }
    });
  }
}
