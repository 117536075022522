import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-handler"
export default class extends Controller {
  static targets = [ "checkbox", "acceptButton" ];
  static classes = [ "disabledButton" ];

  connect() {
    this.acceptButtonTarget.classList.add(this.disabledButtonClass);
  }

  toggle() {
    this.acceptButtonTarget.disabled = !this.checkboxTarget.checked;

    if (this.acceptButtonTarget.disabled) {
      this.acceptButtonTarget.classList.add(this.disabledButtonClass);
    } else {
      this.acceptButtonTarget.classList.remove(this.disabledButtonClass);
    }
  }
}
