import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-confirmation-dialog"
export default class extends Controller {
  static targets = ["deleteModal", "deleteContent", "deleteOverlay", "confirmSubmitForm", "inputForm"];

  // This controller is used for deleting an item or for any other action that requires confirmation such as deleting
  // an account. The dialog box is opened by clicking on a button. The button can be a submit button as
  // part of a form or a regular button.
  show(e) {
    if (this.hasInputFormTarget) {
      // If the form has an input field i.e. password field and the delete button is part of the form,
      // the form is prevented from sending a request if a click event is performed.
      // Instead, the form submit button is used to open the dialog box instead. We then handle the form submission
      // in the submitForm() method.
      e.preventDefault();
    } else {
      // If the form does not have an input field, the form that is wrapped on the button
      // generated by the button_to view helper is submitted immediately. It is mostly used for deleting an item from a
      // list of more similar items.
      // We replace the form action with the delete action param and submit the form.
      // The path to the delete action is a url path containing the id of the item to be deleted
      // defined in the element that activates this action i.e. show(e).
      this.confirmSubmitFormTarget.action = e.params.deleteAction;
    }

    this.deleteModalTarget.classList.remove("hidden");
    this.deleteOverlayTarget.classList.remove("hidden");

    // The animation might not work as expected without the setTimeout() function due to how the
    // browser renders changes to the DOM. When you make multiple changes to the DOM in quick succession, like removing
    // the "hidden" class and then immediately changing the opacity, the browser may batch these changes together and
    // render them all at once for performance reasons. This means that the browser will not render the opacity change
    // until the next frame, and the transition will not be visible.
    // The setTimeout() function is used here to delay the execution of the code inside it until the current call stack
    // is clear. Even with the delay set to 0 milliseconds, the callback won't fire right away but instead, the browser
    // will finish executing the current function and any other functions that are in the call stack before it gets to
    // the callback function inside setTimeout().

    setTimeout(() => {
      this.deleteModalTarget.classList.add("opacity-100");
      this.deleteModalTarget.classList.remove("opacity-0");

      this.deleteOverlayTarget.classList.add("opacity-70");
      this.deleteOverlayTarget.classList.remove("opacity-0");
    }, 0);
  }

  hide() {
    this.deleteOverlayTarget.classList.remove("opacity-70");
    this.deleteOverlayTarget.classList.add("opacity-0");

    this.deleteModalTarget.classList.remove("opacity-100");
    this.deleteModalTarget.classList.add("opacity-0");

    const modalTransitionEndHandler = () => {
      this.deleteOverlayTarget.classList.add("hidden");
      this.deleteModalTarget.classList.add("hidden");
      this.deleteModalTarget.removeEventListener("transitionend", modalTransitionEndHandler);
    };

    this.deleteModalTarget.addEventListener("transitionend", modalTransitionEndHandler);
  }

  submitEnd() {
    this.confirmSubmitFormTarget.addEventListener("turbo:submit-end", () => {
      this.handleModalClose();
    });
  }

  closeWithEscapeKey(e) {
    if (e.code === "Escape") {
      this.handleModalClose();
    }
  }

  closeWithClickOnOverlay(e) {
    if (this.deleteContentTarget.contains(e.target)) {
      return;
    }
    this.handleModalClose();
  }

  handleModalClose(){
    this.hide();
    this.clearFormAction();
  }

  clearFormAction() {
    if (this.hasInputFormTarget) return;

    this.confirmSubmitFormTarget.action = '/';
  }

  submitForm() {
    this.inputFormTarget.submit();
    this.handleModalClose();
  }
}
