import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-selector"
export default class extends Controller {
  static targets = ["hiddenInputFieldChart", "hiddenInputFieldFilters", "optionSelector", "minAgeInput", "maxAgeInput"];

  changeChart(event) {
    this.hiddenInputFieldChartTarget.value = event.currentTarget.value;
    this.hiddenInputFieldChartTarget.form.requestSubmit();
  }

  deleteFilter(event) {
    const filterValue = event.currentTarget.value;
    const remainingFilters = this.removeFilterFromHiddenInputValue(filterValue);

    if (filterValue === 'age') {
      this.clearAgeInputFields();
    } else {
      this.disableOptionSelector(filterValue);
    }

    this.hiddenInputFieldFiltersTarget.value = JSON.stringify(remainingFilters);
    this.hiddenInputFieldFiltersTarget.form.requestSubmit();
  }

  addFilterSelector(event) {
    this.hiddenInputFieldFiltersTarget.value = event.currentTarget.value;

    this.hiddenInputFieldFiltersTarget.form.requestSubmit();
  }

  clearAgeInputFields() {
    this.minAgeInputTarget.value = '';
    this.maxAgeInputTarget.value = '';
  }

  // When removing the filter, we need to disable the corresponding option selector to prevent the selected
  // option values for the removed filter from being submitted.
  disableOptionSelector(selectorName) {
    const filterOptionSelector = this.optionSelectorTargets.find((selector) => selector.name === `${selectorName}[]`);
    filterOptionSelector.disabled = true;
  }

  // Remove the filter from the hidden input field value and updated the hidden input field value without the deleted
  // filter.
  removeFilterFromHiddenInputValue(filter) {
    const selectors = JSON.parse(this.hiddenInputFieldFiltersTarget.value);
    const result = selectors.filter((selector) => selector !== filter);

    return result;
  }
}
