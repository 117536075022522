import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core";

// Connects to data-controller="popper"
export default class extends Controller {
  static targets = ["tooltip", "element"];
  static values = {
    placement: { type: String, default: "bottom-end" },
    offset: { type: Array, default: [0, 8]}
  };

  connect() {
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offsetValue
          }
        }
      ]
    });
  }

  show() {
    this.tooltipTarget.setAttribute("data-show", "");

    this.popperInstance.update();
  }

  hide() {
    this.tooltipTarget.removeAttribute("data-show");
  }

  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }

}
