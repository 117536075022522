import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disclosure"
export default class extends Controller {
  static targets = [ "content", "closeIcon", "openIcon" ];
  static classes = [ "rotateAngle", "maxHeight" ]

  toggle() {
    this.contentTarget.classList.toggle(this.maxHeightClass);

    const isCloseIconVisible = this.closeIconTarget.classList.contains("block");

    if (isCloseIconVisible) {
      this.hideCloseIcon();
    } else {
      this.hideOpenIcon();
    }
  }

  hideCloseIcon() {
    this.closeIconTarget.classList.remove("block");
    this.closeIconTarget.classList.add("hidden");

    this.openIconTarget.classList.remove("hidden");
    this.openIconTarget.classList.add("block");
  }

  hideOpenIcon() {
    this.openIconTarget.classList.remove("block");
    this.openIconTarget.classList.add("hidden");

    this.closeIconTarget.classList.remove("hidden");
    this.closeIconTarget.classList.add("block");
  }
}
