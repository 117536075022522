import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-dialog"
export default class extends Controller {
  static targets = ["modal", "content", "overlay", "focusField", "validationErrorContainer"];

  show() {
    this.modalTarget.classList.remove("hidden");
    this.overlayTarget.classList.remove("hidden");

    setTimeout(() => {
      this.modalTarget.classList.add("opacity-100");
      this.modalTarget.classList.remove("opacity-0");

      this.overlayTarget.classList.add("opacity-70");
      this.overlayTarget.classList.remove("opacity-0");

      if (this.hasFocusFieldTarget) {
        // This needs to be delayed to ensure the modal is visible before focusing
        this.focusFieldTarget.focus();
      }
    }, 0);
  }

  hide() {
    this.overlayTarget.classList.remove("opacity-70");
    this.overlayTarget.classList.add("opacity-0");

    this.modalTarget.classList.remove("opacity-100");
    this.modalTarget.classList.add("opacity-0");

    const modalTransitionEndHandler = () => {
      this.overlayTarget.classList.add("hidden");
      this.modalTarget.classList.add("hidden");
      this.modalTarget.removeEventListener("transitionend", modalTransitionEndHandler);

      if (this.hasValidationErrorContainerTarget) {
        this.validationErrorContainerTarget.innerHTML = "";
      }
    };

    this.modalTarget.addEventListener("transitionend", modalTransitionEndHandler);
  }

  closeWithEscapeKey(e) {
    if (e.code === "Escape") {
      this.handleModalClose();
    }
  }

  closeWithClickOnOverlay(e) {
    if (this.contentTarget.contains(e.target)) {
      return;
    }
    this.handleModalClose();
  }

  handleModalClose(){
    this.hide();
  }
}
